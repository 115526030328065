import React from 'react'
import work1 from '../../assets/work1.png'
import work2 from '../../assets/work2.png'
import work3 from '../../assets/work3.png'
import work4 from '../../assets/work4.png'
import work5 from '../../assets/work5.png'
import work6 from '../../assets/work6.png'
import work7 from '../../assets/work7.png'
import bootcamp from '../../assets/Bootcamp.pdf'
import webshop from '../../assets/webshop.pdf'

import './Projects.css'
const Projects = () => {
  return (
    <section className="work section" id="projects">
      <div className="titles">
      <h2 className="section__title">Projects</h2>
        <span className="section__subtitle">
            My recent work
        </span>
      </div>

        <div className="works__container container grid">

        <div className="work__grid">
            <img src={work7} alt="" className="work__img" />

            <h1 className="work__title">React Native + Expo + Supabase</h1>

            <div className="work__buttons">
              {/* <a href="https://app-realestate-production.up.railway.app/" target="_blank" className="button button--flex button--small work__button" rel="noopener noreferrer" disable>
                Website (WIP)
                <i className="bx bx-link-alt button__icon"></i>
              </a> */}
              {/* github link */}
              <a href="https://github.com/woutertack/PEAK" target="_blank" className="button button--flex button--small work__button" rel="noopener noreferrer">
                Github
                <i className="bx uil-github button__icon"></i>
              </a>
            </div>
          </div>

          <div className="work__grid">
            <img src={work1} alt="" className="work__img" />

            <h1 className="work__title">React JS + MongoDB</h1>

            <div className="work__buttons">
              <a href="https://app-realestate-production.up.railway.app/" target="_blank" className="button button--flex button--small work__button" rel="noopener noreferrer" disable>
                Website (WIP)
                <i className="bx bx-link-alt button__icon"></i>
              </a>
              {/* github link */}
              <a href="https://github.com/woutertack/home-findr/tree/main" target="_blank" className="button button--flex button--small work__button" rel="noopener noreferrer">
                Github
                <i className="bx uil-github button__icon"></i>
              </a>
            </div>
          </div>

          <div className="work__grid">
            <img src={work2} alt="" className="work__img" />

            <h1 className="work__title">React JS + Three JS</h1>

            <div className="work__buttons">
              <a href="https://gdmgent-2223-xr.github.io/2ek-woutertack/#/rapier/world" target="_blank" className="button button--flex button--small work__button" rel="noopener noreferrer">
                Website 
                <i className="bx bx-link-alt button__icon"></i>
              </a>
              {/* github link */}
              <a href="https://github.com/woutertack/FPP-Portfolio" target='_blank' className="button button--flex button--small work__button" rel="noopener noreferrer">
                Github
                <i className="bx uil-github button__icon"></i>
              </a>
            </div>
          </div>

          <div className="work__grid">
            <img src={work3} alt="" className="work__img" />

            <h1 className="work__title">Javascript vanilla + Firebase</h1>

            <div className="work__buttons">
              <a href="https://agenda-app-798fb.firebaseapp.com/" target="_blank" className="button button--flex button--small work__button" rel="noopener noreferrer">
                Website
                <i className="bx bx-link-alt button__icon"></i>
              </a>
              {/* github link */}
              <a href="https://github.com/woutertack/Plan-It" target="_blank" className="button button--flex button--small work__button" rel="noopener noreferrer">
                Github
                <i className="bx uil-github button__icon"></i>
              </a>
            </div>
          </div>

          <div className="work__grid">
            <img src={work4} alt="" className="work__img" />

            <h1 className="work__title">Laravel + Postgres</h1>

            <div className="work__buttons">
              {/* <a href="#projects" className="button button--flex button--small work__button" rel="noopener noreferrer">
                Website (WIP)
                <i className="bx bx-link-alt button__icon"></i>
              </a> */}
              {/* github link */}
              <a href="https://github.com/woutertack/babylist" target="_blank" className="button button--flex button--small work__button" rel="noopener noreferrer">
                Github
                <i className="bx uil-github button__icon"></i>
              </a>
            </div>
          </div>

          <div className="work__grid">
            <img src={work5} alt="" className="work__img" />

            <h1 className="work__title">Design project Flemish government </h1>

            <div className="work__buttons">
              <a download="" href={bootcamp} className="button button--flex button--small work__button" rel="noopener noreferrer">
                Download PDF
                <i className="bx bx-download button__icon"></i>
              </a>
            
            </div>
          </div>

          <div className="work__grid">
            <img src={work6} alt="" className="work__img" />

            <h1 className="work__title">Design project webshop</h1>

            <div className="work__buttons">
              <a download="" href={webshop} className="button button--flex button--small work__button" rel="noopener noreferrer">
                Download PDF
                <i className="bx bx-download button__icon"></i>
              </a>
              
            </div>
          </div>
          

        </div>
    </section>
  )
}

export default Projects